<template>
  <div class="produto-container contratar-container" v-if="!produtoAtivo">
    <div v-if="loading">
      <sl-loading :content="'SL'" />
    </div>
    <div class="produto contratar" v-else-if="!produtoContratado">
      <div @click="$router.push({name: 'marketplace.dashboard'})" class="close-btn"><i class="fa-solid fa-times" /> </div>
      <div class="produto-header">
        <div class="pheader-desc">
          <div>Você está prestes a contratar:</div>
          <h2>{{produto.nome}}</h2>
        </div>
      </div>
      <div class="produto-contrato">
        <iframe ref="iframe" style="width: 100%; border: none; background-color: #FFFFFF;"></iframe>
      </div>
      <div class="produto-opts">
        <div class="definicao-preco">
          <table class="full-width">
            <tr>
              <td>Taxa de Ativação</td>
              <td v-if="produto.taxaInstalacao">R$ {{produto.taxaInstalacao|moeda}}</td>
              <td v-else>Sem custo</td>
            </tr>
            <tr>
              <td>Custo</td>
              <td v-if="produto.criterioUsos && produto.criterioUsos.length">De acordo uso <span v-if="produto.unidadeMedidaCobrancaDescricao">({{produto.unidadeMedidaCobrancaDescricao}})</span></td>
              <td v-else>R$ {{produto.valor|moeda2}} <span v-if="produto.unidadeMedidaCobrancaDescricao">({{produto.unidadeMedidaCobrancaDescricao}})</span></td>
            </tr>
            <tr v-if="produto.definicaoPreco.periodicidades && produto.definicaoPreco.periodicidades.length">
              <td>Período do contrato</td>
              <td>
                <select class="full-width" v-model="periodicidade">
                  <option :value="p.periodo" v-for="p in produto.definicaoPreco.periodicidades">{{p.label}} <span v-if="p.descontoPorcentagem">({{p.descontoPorcentagem}}% de desconto)</span></option>
                </select>
              </td>
            </tr>
            <tr v-if="produto.criterioCobranca === criteriosCobranca.CRITERIO_COBRANCA_UNICA">
              <td>Quantidade</td>
              <td>
                <select class="full-width" v-model="quantidade">
                  <option :value="p" v-for="p in 10">{{p}}</option>
                </select>
              </td>
            </tr>
            <tr>
              <td>Você vai pagar agora</td>
              <td v-if="periodicidade > 1" class="text-yellow">
                R$ {{produtoPeriodicidade.pagarAgora|moeda}} <span v-if="produtoPeriodicidade.prorata" class="font-12">(pro rata)</span>
              </td>
              <td class="text-yellow" v-else>R$ {{(Number(produto.definicaoPreco.pagarAgora) * quantidade)|moeda}} <span v-if="produto.definicaoPreco.prorata" class="font-12">(pro rata)</span></td>
            </tr>
            <tr>
              <td>Próximo vencimento</td>
              <td v-if="periodicidade > 1" class="">
                {{produtoPeriodicidade.proximoVencimento|formatDate('dd/MM/yyyy')}} <span class="font-12" v-if="produtoPeriodicidade.prorata">(R$ {{produtoPeriodicidade.valor|moeda}})</span>
              </td>
              <td class="" v-else>{{produto.definicaoPreco.proximoVencimento|formatDate('dd/MM/yyyy')}} <span class="font-12" v-if="produto.definicaoPreco.prorata">(R$ {{produto.definicaoPreco.valor|moeda}})</span></td>
            </tr>
            <tr v-if="produto.criterioUsos && produto.criterioUsos.length">
              <td colspan="100%" style="text-align: left; padding-top: 12px; padding-bottom: 4px">
                Definições de Custo {{produto.unidadeMedidaCobrancaDescricao}}
              </td>
            </tr>
            <tr v-for="criterio in produto.criterioUsos">
              <td>
                <div v-if="criterio.limiteSuperior == '0' || criterio.limiteSuperior">
                  De {{criterio.limiteInferior|number}} até {{criterio.limiteSuperior|number}}
                </div>
                <div v-else>A partir de {{criterio.limiteInferior|number}}</div>
              </td>
              <td>R$ {{criterio.valorUnidade|moeda2}}</td>
            </tr>
          </table>
        </div>
        <div class="termos">
          <u-checkbox color="white" dark v-model="aceite" />
          <div class="m-l-xs">Li e concordo com as condições de uso, definições de preço e contrato de serviço</div>
        </div>
        <div class="aceite-opts">
          <u-btn :disable="!aceite" @click="contratar" :label="!produto.valor && !produto.taxaInstalacao ? 'Ativar sem custo' : 'Contratar e ativar'" :loading="loadingContratar" color="green" no-caps class="b-radius-3px" />
        </div>
      </div>
    </div>
    <div class="produto contratar flex items-center justify-center content-center" style="justify-content: center !important;" v-else-if="produtoContratado && produtoContratado.pagarAgora">
      <h5 class="">
        Obrigado por adquirir nossos serviços.
      </h5>
      <div class="m-t-sm flex items-center justify-center column">
        <div class="m-b">Aguarde enquanto direcionamos você para o pagamento...</div>
        <sl-loading :content="''" class="size-18" />
      </div>
    </div>
    <div class="produto contratar flex items-center justify-center content-center" style="justify-content: center !important;" v-else-if="produtoContratado && !produtoContratado.pagarAgora">
      <h5 class="">
        Obrigado por utilizar nossos serviços.
      </h5>
      <div class="m-t-sm flex items-center justify-center column">
        <div class="m-b">Estamos configurando o produto e <span style="color: greenyellow">ativando o serviço...</span></div>
        <sl-loading :content="''" class="size-18" />
      </div>
    </div>
    <div class="produto contratar flex items-center justify-center content-center" style="justify-content: center !important;" v-else>
      <h5 class="">
        Falha ao processar a compra.
      </h5>
    </div>
  </div>
  <div class="produto-container contratar-container" v-else>
    <div v-if="produtoContratado.produto.autoconfigure" class="produto contratar flex items-center justify-center content-center" style="justify-content: center !important;">
      <div class="success-checkmark">
        <div class="check-icon">
          <span class="icon-line line-tip"></span>
          <span class="icon-line line-long"></span>
          <div class="icon-circle"></div>
          <div class="icon-fix"></div>
        </div>
      </div>
      <div class="m-t">
        Serviço <strong>configurado com sucesso</strong> e já está disponível para uso.
      </div>
      <div class="m-t">
        <u-btn @click="$router.push({name: 'marketplace.dashboard'})" no-caps color="green" label="Concluir" class="b-radius-3px" />
      </div>
    </div>
    <div v-else class="produto contratar flex items-center justify-center content-center" style="justify-content: center !important;">
      <h5 class="">
        Seu serviço está próximo de ser ativado!
      </h5>
      <div class="m-t">
        Este serviço precisa de ativação manual da nossa equipe. Foi aberto uma ordem de serviço para o contrato ID <strong>{{String(produtoContratado.contrato.id).padStart(6, '0')}}</strong>, em breve faremos contrato.
      </div>
      <div class="m-t">
        <u-btn @click="$router.push({name: 'marketplace.dashboard'})" no-caps color="green" label="Concluir" class="b-radius-3px" />
      </div>
    </div>
  </div>
</template>

<script>
import SlLoading from "components/layout/components/Loading.vue"
import {findContratoUso, contratarProduto, ativarContrato} from "@/domain/marketplace/services/produtos"
import {UCheckbox} from "uloc-vue"
import * as FormasPagamento from "@/domain/gerencia/produtos/helpers/formaPagamento"
import * as CriteriosCobranca from "@/domain/gerencia/produtos/helpers/criterioCobranca"

export default {
  name: "Contratar",
  components: {
    SlLoading,
    UCheckbox
  },
  data () {
    return {
      loading: true,
      loadingContratar: false,
      produto: null,
      contrato: null,
      periodicidade: 1,
      quantidade: 1,
      aceite: false,
      produtoContratado: null,
      produtoAtivo: null
    }
  },
  computed: {
    formasPagamento () {
      return FormasPagamento
    },
    criteriosCobranca () {
      return CriteriosCobranca
    },
    produtoPeriodicidade () {
      return this.produto.definicaoPreco.periodicidades.find(p => p.periodo === this.periodicidade) ?? {}
    }
  },
  mounted () {
    this.loadContratoUso()
  },
  methods: {
    loadContratoUso () {
      findContratoUso(this.$route.params.id, true)
          .then(response => {
            this.loading = false
            this.produto = response.data.produto
            this.contrato = response.data.contrato
            this.$nextTick(() => {
              try {
                const iframe = this.$refs.iframe
                const doc = iframe.contentDocument || iframe.contentWindow.document
                doc.open()
                doc.write(this.contrato)
                doc.close()
              } catch (e) {
                console.error(e)
              }
            })
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    contratar () {
      if (!this.aceite) return
      this.loadingContratar = true
      const data = {
        periodicidade: this.periodicidade,
        quantidade: this.quantidade
      }
      contratarProduto(this.produto.id, data)
          .then(response => {
            console.log(response.data)
            this.loadingContratar = false
            this.produtoContratado = response.data
            this.$nextTick(() => {
              if (!this.produtoContratado.pagarAgora) {
                ativarContrato(this.produtoContratado.contrato.id)
                    .then((response) => {
                      this.produtoAtivo = response.data
                    })
                    .catch(error => {
                      this.alertApiError(error)
                    })
              } else {
                setTimeout(() => {
                  this.$router.push({
                    name: 'suporte.cliente.faturas.pagarFatura',
                    params: {id: response.data.invoice.id}
                  })
                }, 3000)
              }
            })
          })
          .catch(error => {
            this.loadingContratar = false
            this.alertApiError(error)
          })
    }
  }
}
</script>
